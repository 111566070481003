import * as React from "react";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
export const FreeText = (props) => {
    const cssClass = `free-text${props.isWarningText ? " free-text--warning" : ""}${props.className ? ` ${props.className}` : ""}`;
    return (React.createElement("div", { className: cssClass, style: { backgroundColor: props.backgroundColor ? props.backgroundColor : "#fff" } },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                props.heading && React.createElement(Heading, { design: "h2-regular" }, props.heading),
                React.createElement("div", { className: "free-text__content", dangerouslySetInnerHTML: { __html: props.content } })))));
};
