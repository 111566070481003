import React from "react";
const DarkStarSet = () => (React.createElement("svg", { height: "16", width: "81", fill: "none", viewBox: "0 0 81 16", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_4471_232172)" },
        React.createElement("path", { d: "M14.1477 7.99207C14.4677 7.67823 14.5785 7.22286 14.4431 6.79825C14.3077 6.37364 13.9447 6.06595 13.5016 6.00442L10.8986 5.62288C10.8186 5.61058 10.7509 5.56135 10.714 5.4875L9.5509 3.12446C9.35398 2.72447 8.95399 2.47217 8.50477 2.47217C8.05554 2.47217 7.65555 2.72447 7.45863 3.12446L6.29557 5.4875C6.25865 5.56135 6.19096 5.61058 6.11096 5.62288L3.50793 6.00442C3.06486 6.06595 2.70179 6.37364 2.5664 6.79825C2.43102 7.22286 2.54179 7.68439 2.86178 7.99207L4.74483 9.82589C4.80021 9.88127 4.83098 9.96127 4.81252 10.0413L4.36945 12.6382C4.29561 13.0812 4.47406 13.5181 4.83098 13.7766C5.03405 13.9243 5.27405 13.9981 5.51405 13.9981C5.69866 13.9981 5.88327 13.9551 6.05558 13.8627L8.38784 12.6382C8.45553 12.6012 8.54169 12.6012 8.61553 12.6382L10.9478 13.8627C11.3416 14.072 11.8155 14.0412 12.1785 13.7766C12.5416 13.512 12.7201 13.0751 12.6401 12.6382L12.197 10.0413C12.1847 9.96127 12.2093 9.88127 12.2647 9.82589L14.1477 7.98592V7.99207Z", fill: "#0E1D31" })),
    React.createElement("g", { clipPath: "url(#clip1_4471_232172)" },
        React.createElement("path", { d: "M30.1477 7.99207C30.4677 7.67823 30.5785 7.22286 30.4431 6.79825C30.3077 6.37364 29.9447 6.06595 29.5016 6.00442L26.8986 5.62288C26.8186 5.61058 26.7509 5.56135 26.714 5.4875L25.5509 3.12446C25.354 2.72447 24.954 2.47217 24.5048 2.47217C24.0555 2.47217 23.6555 2.72447 23.4586 3.12446L22.2956 5.4875C22.2586 5.56135 22.191 5.61058 22.111 5.62288L19.5079 6.00442C19.0649 6.06595 18.7018 6.37364 18.5664 6.79825C18.431 7.22286 18.5418 7.68439 18.8618 7.99207L20.7448 9.82589C20.8002 9.88127 20.831 9.96127 20.8125 10.0413L20.3694 12.6382C20.2956 13.0812 20.4741 13.5181 20.831 13.7766C21.0341 13.9243 21.274 13.9981 21.514 13.9981C21.6987 13.9981 21.8833 13.9551 22.0556 13.8627L24.3878 12.6382C24.4555 12.6012 24.5417 12.6012 24.6155 12.6382L26.9478 13.8627C27.3416 14.072 27.8155 14.0412 28.1785 13.7766C28.5416 13.512 28.7201 13.0751 28.6401 12.6382L28.197 10.0413C28.1847 9.96127 28.2093 9.88127 28.2647 9.82589L30.1477 7.98592V7.99207Z", fill: "#0E1D31" })),
    React.createElement("g", { clipPath: "url(#clip2_4471_232172)" },
        React.createElement("path", { d: "M46.1477 7.99207C46.4677 7.67823 46.5785 7.22286 46.4431 6.79825C46.3077 6.37364 45.9447 6.06595 45.5016 6.00442L42.8986 5.62288C42.8186 5.61058 42.7509 5.56135 42.714 5.4875L41.5509 3.12446C41.354 2.72447 40.954 2.47217 40.5048 2.47217C40.0555 2.47217 39.6555 2.72447 39.4586 3.12446L38.2956 5.4875C38.2586 5.56135 38.191 5.61058 38.111 5.62288L35.5079 6.00442C35.0649 6.06595 34.7018 6.37364 34.5664 6.79825C34.431 7.22286 34.5418 7.68439 34.8618 7.99207L36.7448 9.82589C36.8002 9.88127 36.831 9.96127 36.8125 10.0413L36.3694 12.6382C36.2956 13.0812 36.4741 13.5181 36.831 13.7766C37.0341 13.9243 37.274 13.9981 37.514 13.9981C37.6987 13.9981 37.8833 13.9551 38.0556 13.8627L40.3878 12.6382C40.4555 12.6012 40.5417 12.6012 40.6155 12.6382L42.9478 13.8627C43.3416 14.072 43.8155 14.0412 44.1785 13.7766C44.5416 13.512 44.7201 13.0751 44.6401 12.6382L44.197 10.0413C44.1847 9.96127 44.2093 9.88127 44.2647 9.82589L46.1477 7.98592V7.99207Z", fill: "#0E1D31" })),
    React.createElement("g", { clipPath: "url(#clip3_4471_232172)" },
        React.createElement("path", { d: "M62.1477 7.99207C62.4677 7.67823 62.5785 7.22286 62.4431 6.79825C62.3077 6.37364 61.9447 6.06595 61.5016 6.00442L58.8986 5.62288C58.8186 5.61058 58.7509 5.56135 58.714 5.4875L57.5509 3.12446C57.354 2.72447 56.954 2.47217 56.5048 2.47217C56.0555 2.47217 55.6555 2.72447 55.4586 3.12446L54.2956 5.4875C54.2586 5.56135 54.191 5.61058 54.111 5.62288L51.5079 6.00442C51.0649 6.06595 50.7018 6.37364 50.5664 6.79825C50.431 7.22286 50.5418 7.68439 50.8618 7.99207L52.7448 9.82589C52.8002 9.88127 52.831 9.96127 52.8125 10.0413L52.3694 12.6382C52.2956 13.0812 52.4741 13.5181 52.831 13.7766C53.0341 13.9243 53.274 13.9981 53.514 13.9981C53.6987 13.9981 53.8833 13.9551 54.0556 13.8627L56.3878 12.6382C56.4555 12.6012 56.5417 12.6012 56.6155 12.6382L58.9478 13.8627C59.3416 14.072 59.8155 14.0412 60.1785 13.7766C60.5416 13.512 60.7201 13.0751 60.6401 12.6382L60.197 10.0413C60.1847 9.96127 60.2093 9.88127 60.2647 9.82589L62.1477 7.98592V7.99207Z", fill: "#0E1D31" })),
    React.createElement("g", { clipPath: "url(#clip4_4471_232172)" },
        React.createElement("path", { d: "M78.1477 7.99207C78.4677 7.67823 78.5785 7.22286 78.4431 6.79825C78.3077 6.37364 77.9447 6.06595 77.5016 6.00442L74.8986 5.62288C74.8186 5.61058 74.7509 5.56135 74.714 5.4875L73.5509 3.12446C73.354 2.72447 72.954 2.47217 72.5048 2.47217C72.0555 2.47217 71.6555 2.72447 71.4586 3.12446L70.2956 5.4875C70.2586 5.56135 70.191 5.61058 70.111 5.62288L67.5079 6.00442C67.0649 6.06595 66.7018 6.37364 66.5664 6.79825C66.431 7.22286 66.5418 7.68439 66.8618 7.99207L68.7448 9.82589C68.8002 9.88127 68.831 9.96127 68.8125 10.0413L68.3694 12.6382C68.2956 13.0812 68.4741 13.5181 68.831 13.7766C69.0341 13.9243 69.274 13.9981 69.514 13.9981C69.6987 13.9981 69.8833 13.9551 70.0556 13.8627L72.3878 12.6382C72.4555 12.6012 72.5417 12.6012 72.6155 12.6382L74.9478 13.8627C75.3416 14.072 75.8155 14.0412 76.1785 13.7766C76.5416 13.512 76.7201 13.0751 76.6401 12.6382L76.197 10.0413C76.1847 9.96127 76.2093 9.88127 76.2647 9.82589L78.1477 7.98592V7.99207Z", fill: "#0E1D31" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_4471_232172" },
            React.createElement("rect", { height: "12", width: "12", fill: "white", transform: "translate(2.5 2)" })),
        React.createElement("clipPath", { id: "clip1_4471_232172" },
            React.createElement("rect", { height: "12", width: "12", fill: "white", transform: "translate(18.5 2)" })),
        React.createElement("clipPath", { id: "clip2_4471_232172" },
            React.createElement("rect", { height: "12", width: "12", fill: "white", transform: "translate(34.5 2)" })),
        React.createElement("clipPath", { id: "clip3_4471_232172" },
            React.createElement("rect", { height: "12", width: "12", fill: "white", transform: "translate(50.5 2)" })),
        React.createElement("clipPath", { id: "clip4_4471_232172" },
            React.createElement("rect", { height: "12", width: "12", fill: "white", transform: "translate(66.5 2)" })))));
export default DarkStarSet;
