import React from "react";
export const Heading = ({ design, style, children }) => {
    if (design?.includes("h5")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h5", { className: design, style: style },
                " ",
                children)));
    }
    if (design?.includes("h4")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h4", { className: design, style: style },
                " ",
                children)));
    }
    if (design?.includes("h3")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h3", { className: design, style: style },
                " ",
                children)));
    }
    if (design?.includes("h1")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: design, style: style },
                " ",
                children)));
    }
    if (design?.includes("jumbo")) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", { className: design, style: style },
                " ",
                children)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: design, style: style },
            " ",
            children)));
};
