import React, { useCallback, useMemo } from "react";
import { handleLinkTarget } from "../../utils";
import { buttonArrowRight, buttonArrowDown, buttonArrowUp } from "../svgIcons/ButtonV2Icons";
export const ButtonV2 = ({ design, classes, ...props }) => {
    const hrefTarget = handleLinkTarget(props.openInNewTab);
    const mountArrows = useCallback(() => React.createElement(React.Fragment, null,
        props.showArrowRight && buttonArrowRight,
        props.showArrowUp && buttonArrowUp,
        props.showArrowDown && buttonArrowDown), [props]);
    const anchor = useMemo(() => React.createElement("a", { href: props.href ? props.href : "#", onClick: props.onClick, className: `button-v2 flex ${design} ${classes || ""}`, style: { ...props.style }, target: hrefTarget },
        props.icon && React.createElement(React.Fragment, null,
            React.createElement("img", { className: "button-v2-icon", src: props.icon }),
            React.createElement("img", { className: "button-v2-icon-hover", src: props.iconHover || props.icon })),
        props.children,
        mountArrows()), [classes, design, props, hrefTarget, mountArrows]);
    const spn = useMemo(() => React.createElement("span", { role: "button", onClick: props.onClick, className: `button-v2 flex ${design} ${classes || ""}`, style: { ...props.style, cursor: "pointer" } },
        props.icon && React.createElement(React.Fragment, null,
            React.createElement("img", { className: "button-v2-icon", src: props.icon }),
            React.createElement("img", { className: "button-v2-icon-hover", src: props.iconHover || props.icon })),
        props.children,
        mountArrows()), [classes, design, props, mountArrows]);
    return (React.createElement("div", { className: `button-v2-wrapper flex ${props.classesWrapper || ""}` }, props.href ? anchor : spn));
};
