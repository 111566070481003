import React from "react";
import { ContainerFW, Container } from "../global";
import { PriceFeedNewSocketComponent } from "./PriceFeedNewSocketComponent";
export const PriceFeedNew = ({ title, textContent, tableInfo, instrumentIcons, marketsInfo, resources, footerText, session, wsUrl, apiUrl, activeMarket, instrumentParagraph, instrumentLinkText, instrumentLinkUrl, buttonLinkText, buttonLinkUrl, instrumentItems, popularInstrumentToRemove, popularInstrumentToAdd, instrumentsData, culture }) => {
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "price-feed price-feed-new" },
            React.createElement(Container, null,
                React.createElement("div", { className: "price-feed-new__wrap" },
                    React.createElement("div", { className: "price-feed__wrap--left" },
                        React.createElement("h2", null, title),
                        React.createElement("div", { className: "price-feed__column", dangerouslySetInnerHTML: { __html: textContent } })),
                    React.createElement(PriceFeedNewSocketComponent, { title: title, textContent: textContent, tableInfo: tableInfo, instrumentIcons: instrumentIcons, marketsInfo: marketsInfo, resources: resources, footerText: footerText, session: session, wsUrl: wsUrl, apiUrl: apiUrl, activeMarket: activeMarket, instrumentParagraph: instrumentParagraph, instrumentLinkText: instrumentLinkText, instrumentLinkUrl: instrumentLinkUrl, buttonLinkText: buttonLinkText, buttonLinkUrl: buttonLinkUrl, instrumentItems: instrumentItems, popularInstrumentToRemove: popularInstrumentToRemove, popularInstrumentToAdd: popularInstrumentToAdd, instrumentsData: instrumentsData, culture: culture }))))));
};
