import React from "react";
import { Container, ContainerFW } from "../../global";
import { IconAndTitleCard } from "./IconAndTitleCard/IconAndTitleCard";
import { getColors } from "../../../utils";
export const IconAndTitleCardDeck = (props) => {
    const { bgColor } = getColors(props.backgroundColor);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, null,
            React.createElement("div", { className: "icon-and-title-card-deck" },
                React.createElement("div", { className: "icon-and-title-card-deck__text-container" },
                    React.createElement("h3", null, props.title),
                    React.createElement("p", null, props.description)),
                React.createElement("div", { className: "icon-and-title-card-deck__items-container" }, props.cards.map((card, i) => React.createElement(IconAndTitleCard, { ...card, key: i })))))));
};
