import { useEffect, useState } from "react";
import { pageViewStyle } from "../pageViewStyle";
export const usePageViewStyle = () => {
    const [view, setView] = useState(pageViewStyle.getView());
    const onViewUpdate = (newView) => {
        setView(newView);
    };
    useEffect(() => {
        pageViewStyle.subscribe(onViewUpdate);
        return () => {
            pageViewStyle.unsubscribe(onViewUpdate);
        };
    }, []);
    return { viewStyle: view, isSmall: view === "small", isMedium: view === "medium", isLarge: view === "large" };
};
