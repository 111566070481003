import React from "react";
export const IconNoResults = (React.createElement("svg", { width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_16819_137445)" },
        React.createElement("path", { d: "M36.0005 6.00122H32.3084C28.9949 6.00122 26.3088 8.68732 26.3088 12.0008V15.6928C26.3088 19.0063 28.9949 21.6924 32.3084 21.6924H36.0005C39.3139 21.6924 42 19.0063 42 15.6928V12.0008C42 8.68732 39.3139 6.00122 36.0005 6.00122Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M36.0005 26.3074H32.3084C28.9949 26.3074 26.3088 28.9935 26.3088 32.3069V35.999C26.3088 39.3125 28.9949 41.9986 32.3084 41.9986H36.0005C39.3139 41.9986 42 39.3125 42 35.999V32.3069C42 28.9935 39.3139 26.3074 36.0005 26.3074Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M15.6942 6.00122H12.0021C8.68866 6.00122 6.00256 8.68732 6.00256 12.0008V15.6928C6.00256 19.0063 8.68866 21.6924 12.0021 21.6924H15.6942C19.0076 21.6924 21.6937 19.0063 21.6937 15.6928V12.0008C21.6937 8.68732 19.0076 6.00122 15.6942 6.00122Z", fill: "#CFD2D6" }),
        React.createElement("path", { d: "M14.7712 26.3075C10.95 26.3075 7.84864 29.4088 7.84864 33.2301C7.84864 34.6331 8.27322 35.9438 8.99317 37.0329L6.39028 39.6358C5.85493 40.1711 5.85493 41.0572 6.39028 41.5926C6.66718 41.8695 7.01793 41.9987 7.36867 41.9987C7.71941 41.9987 8.07016 41.8695 8.34706 41.5926L10.95 38.9897C12.0391 39.7096 13.3498 40.1342 14.7528 40.1342C18.574 40.1342 21.6753 37.0329 21.6753 33.2116C21.6753 29.3904 18.574 26.2891 14.7528 26.2891L14.7712 26.3075Z", fill: "#0E1D31" })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: "clip0_16819_137445" },
            React.createElement("rect", { width: "36", height: "36", fill: "white", transform: "translate(6 6)" })))));
