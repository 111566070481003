import React from "react";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { ExternalLink } from "../svgIcons/externalLink";
import { Heading } from "../Heading";
import { ImageToggle } from "./ImageToggle";
export const AccessThinkPortalTodayImgToggle = (props) => (React.createElement("div", { className: "access-think-portal-today access-think-portal-today-img-toggle" },
    React.createElement(ContainerFW, null,
        React.createElement(Container, null,
            React.createElement("div", { className: "access-think-portal-today-img-toggle__inner" },
                React.createElement("div", { className: "access-think-portal-today-img-toggle__options-container" },
                    React.createElement(Heading, { design: "h2-regular" }, props.title),
                    React.createElement("p", null, props.description),
                    React.createElement("div", { className: "access-think-portal-today-img-toggle__options" },
                        React.createElement("div", { className: "access-think-portal-today-img-toggle__option access-think-portal-today-img-toggle__option-mobile-app access-think-portal-today-img-toggle--desktop-view" },
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-info" },
                                React.createElement(Heading, { design: "h4-semi-bold" },
                                    React.createElement(Img, { src: props.mobileSVGIcon, alt: props.mobileSVGIconAlt }),
                                    props.buttonMobileTitle),
                                React.createElement("p", null, props.buttonMobileText)),
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-links" },
                                React.createElement(Img, { src: props.mobileQRCode, alt: props.mobileQRCodeAlt, className: "access-think-portal-today-img-toggle__qr-code" }))),
                        React.createElement("div", { className: "access-think-portal-today-img-toggle__option access-think-portal-today-img-toggle__option-mobile-app access-think-portal-today-img-toggle--mobile-view" },
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-info" },
                                React.createElement(Heading, { design: "h4-semi-bold" },
                                    React.createElement(Img, { src: props.mobileSVGIcon, alt: props.mobileSVGIconAlt }),
                                    props.buttonMobileTitle)),
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-links" },
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.googlePlayUrl },
                                    React.createElement(Img, { src: props.googlePlayImageUrl, alt: props.googlePlayImageAlt })),
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.appStoreUrl },
                                    React.createElement(Img, { src: props.appStoreImageUrl, alt: props.appStoreImageAlt })))),
                        React.createElement("div", { className: "access-think-portal-today-img-toggle__option access-think-portal-today-img-toggle__option-web access-think-portal-today-img-toggle--desktop-view" },
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-info" },
                                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.buttonWebLink },
                                    React.createElement(Heading, { design: "h4-semi-bold" },
                                        React.createElement(Img, { src: props.browserSVGIcon, alt: props.browserSVGIconAlt }),
                                        props.buttonWebText))),
                            React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.buttonWebLink }, ExternalLink())),
                        React.createElement("div", { className: "access-think-portal-today-img-toggle__option access-think-portal-today-img-toggle__option-web access-think-portal-today-img-toggle--mobile-view" },
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-info" },
                                React.createElement(Heading, { design: "h4-semi-bold" },
                                    React.createElement(Img, { src: props.browserSVGIcon, alt: props.browserSVGIconAlt }),
                                    props.buttonWebText)),
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-links" },
                                props.buttonWebMobileDescription,
                                " ",
                                ExternalLink())),
                        props.buttonDesktopText && React.createElement("div", { className: "access-think-portal-today-img-toggle__option access-think-portal-today-img-toggle__option-desktop-app" },
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-info" },
                                React.createElement(Heading, { design: "h4-semi-bold" },
                                    React.createElement(Img, { src: props.desktopSVGIcon, alt: props.desktopSVGIconAlt }),
                                    props.buttonDesktopText)),
                            React.createElement("div", { className: "access-think-portal-today-img-toggle__option-links" },
                                React.createElement("a", { rel: "noopener", href: props.buttonDesktopWindowsLink }, props.buttonDesktopWindowsText),
                                React.createElement("a", { rel: "noopener", href: props.buttonDesktopMacLink }, props.buttonDesktopMacText))))),
                React.createElement("div", { className: "access-think-portal-today-img-toggle__img-block" },
                    React.createElement(ImageToggle, { toggleImages: {
                            mainImageUrl: props.mainImageUrl,
                            mainImageAlt: props.mainImageAlt,
                            toggledImageUrl: props.toggledImageUrl,
                            toggledImageAlt: props.toggledImageAlt
                        }, imgContainerCssClass: "access-think-portal-today-img-toggle__img-container" })))))));
