import React, { useEffect, useState } from "react";
import { ButtonSquare } from "../../ButtonSquare";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { usePageViewStyle } from "../../../hooks";
export const HomePageMainBanner = ({ title, description, desktopAnimation, mobileAnimation, buttonLabel, buttonUrl, buttonOpenInNewTab, linkHref, linkLabel, textBelowCTA = "" }) => {
    const { isLarge, isMedium } = usePageViewStyle();
    const [animationPath, setAnimationPath] = useState(isLarge ? desktopAnimation : mobileAnimation);
    const [animationWidth, setAnimationWidth] = useState(isLarge ? 1920 : 744);
    const [animationHeight, setAnimationHeight] = useState(isLarge ? 650 : 500);
    const checkHasLink = () => linkHref && linkLabel;
    useEffect(() => {
        if (isLarge) {
            setAnimationPath(desktopAnimation);
            setAnimationWidth(1920);
            setAnimationHeight(650);
        }
        else {
            setAnimationPath(mobileAnimation);
            if (isMedium) {
                setAnimationWidth(744);
                setAnimationHeight(500);
            }
            else {
                setAnimationWidth(375);
                setAnimationHeight(253);
            }
        }
    }, [animationPath, isLarge, isMedium, desktopAnimation, mobileAnimation]);
    return (React.createElement("div", { className: "home-page-main-banner-background" },
        React.createElement("div", { className: "home-page-main-banner" },
            React.createElement("div", { className: "home-page-main-banner__text-button-wrapper" },
                React.createElement("div", { className: "home-page-main-banner__text-button-box" },
                    React.createElement("h1", { dangerouslySetInnerHTML: { __html: title } }),
                    React.createElement("p", null, description),
                    buttonUrl && React.createElement(ButtonSquare, { label: buttonLabel, href: buttonUrl, openinnewtab: buttonOpenInNewTab.toString() }),
                    checkHasLink() && React.createElement("a", { href: linkHref, className: "home-page-main-banner__text-link" }, linkLabel),
                    React.createElement("div", { dangerouslySetInnerHTML: { __html: textBelowCTA }, className: "home-page-main-banner__text-below-cta" }))),
            React.createElement(DotLottieReact, { src: animationPath, loop: true, autoplay: true, width: animationWidth, height: animationHeight }))));
};
