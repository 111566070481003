import React from "react";
import { ButtonV2 } from "../../ButtonV2";
import { Container, ContainerFW } from "../../global";
import { Heading } from "../../Heading";
export const BannerImageBackground = ({ buttonHref, buttonLabel, openInNewTabButton, subTitle, title, imageUrl, backgroundColor, gradient1, gradient2, buttonHref2, buttonLabel2, openInNewTabButton2 }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement("div", { className: "banner-image-background", style: { "--background-image-url": `url(${imageUrl})`, "--gradient-1": `${gradient1}`, "--gradient-2": `${gradient2}` } },
        React.createElement(Container, { className: "g-container--tablet-margins" },
            React.createElement("div", { className: "banner-image-background__contentWrapper" },
                React.createElement(Heading, { design: "h3-lp" }, title),
                React.createElement("p", null, subTitle),
                React.createElement("div", { className: "banner-image-background__button-wrapper" },
                    buttonHref2 && React.createElement(ButtonV2, { href: buttonHref2, openInNewTab: openInNewTabButton2, design: "cta-large-wbg" }, buttonLabel2),
                    buttonHref && React.createElement(ButtonV2, { href: buttonHref, openInNewTab: openInNewTabButton, design: "secondary-large-wbg" }, buttonLabel)))))));
