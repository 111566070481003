import * as React from "react";
import { handleLinkTarget } from "../../../utils";
import { ContainerFW } from "../../global";
import { ImgV2 } from "../../ImgV2";
export const RibbonBanner3 = ({ iconImgUrl, iconImgAlt, mainText, linkText, linkUrl, linkOpenInNewTab }) => {
    const linkHrefTarget = handleLinkTarget(linkOpenInNewTab);
    return (React.createElement(ContainerFW, null,
        React.createElement("div", { className: "ribbon-banner3 centered-container color-pure-white bg-color-dark-background-ribbon" },
            React.createElement(ImgV2, { src: iconImgUrl, alt: iconImgAlt }),
            React.createElement("span", { className: "ribbon-banner3__main-text", dangerouslySetInnerHTML: {
                    __html: mainText
                } }),
            React.createElement("a", { href: linkUrl, target: linkHrefTarget, className: "ribbon-banner3__main-text color-pure-white" }, linkText))));
};
