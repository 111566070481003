import React, { useCallback, useState } from "react";
import { Img } from "../../Img";
export const ImageToggle = ({ toggleImages: { mainImageUrl, mainImageAlt, toggledImageUrl, toggledImageAlt, }, imgContainerCssClass }) => {
    const [isImageToggled, setIsImageToggled] = useState(undefined);
    const handleShowMainImage = useCallback(() => {
        setIsImageToggled(false);
    }, []);
    const handleShowToggledImage = useCallback(() => {
        setIsImageToggled(true);
    }, []);
    return (React.createElement("div", { className: `image-toggle image-toggle__main-image ${isImageToggled ? "image-toggle--toggled" : ""} ${isImageToggled !== undefined ? "image-toggle--activate-animations" : ""}` },
        React.createElement("div", { className: `image-toggle__image-container ${imgContainerCssClass}` },
            React.createElement("div", { className: "image-toggle__main-image" },
                React.createElement(Img, { src: mainImageUrl, alt: mainImageAlt })),
            React.createElement("div", { className: "image-toggle__toggled-image" },
                React.createElement(Img, { src: toggledImageUrl, alt: toggledImageAlt }))),
        React.createElement("div", { className: `image-toggle__control ${isImageToggled ? " image-toggle__control--toggled" : ""}` },
            React.createElement("div", { className: "image-toggle__control-option image-toggle__control-option--main", onClick: handleShowMainImage }),
            React.createElement("div", { className: "image-toggle__control-option image-toggle__control-option--toggled", onClick: handleShowToggledImage }))));
};
