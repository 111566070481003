import React, { useState, useEffect, useCallback } from "react";
import { Container } from "../../global";
import { ButtonSquare } from "../../ButtonSquare";
import { Img } from "../../Img";
import { usePageViewStyle } from "../../../hooks";
export const HeroBannerWithImagesNew = ({ title, subtitle, desktopImage, mobileImage, stats, buttonLabel, buttonHref, buttonHrefMobile, buttonOpenInNewTab, linkHref, linkLabel, backgroundColor }) => {
    const [buttonUrl, setButtonUrl] = useState(buttonHref);
    const { isLarge } = usePageViewStyle();
    const [desktopImageEl, setDesktopImageEl] = useState(React.createElement(React.Fragment, null));
    const [mobileImageEl, setMobileImageEl] = useState(React.createElement(React.Fragment, null));
    useEffect(() => {
        setDesktopImageEl(isLarge ? React.createElement(Img, { src: desktopImage, alt: title, loading: "eager" }) : React.createElement(React.Fragment, null));
        setMobileImageEl(!isLarge ? React.createElement(Img, { src: mobileImage, alt: title, loading: "eager" }) : React.createElement(React.Fragment, null));
    }, [isLarge, desktopImage, mobileImage, title]);
    useEffect(() => {
        setButtonUrl((!isLarge && buttonHrefMobile) ? buttonHrefMobile : buttonHref);
    }, [isLarge, buttonHrefMobile, buttonHref]);
    const mainClassName = `${backgroundColor === "White" ? "HeroBannerWithImagesWrapper HeroBannerWithImagesNew white-background" : "HeroBannerWithImagesWrapper HeroBannerWithImagesNew"}`;
    const emptyStatisticsTest = stats === undefined ? 0 : stats.filter(o => Object.values(o).every(x => x !== "")).length;
    const cardsRendering = useCallback(() => {
        if (stats && (emptyStatisticsTest > 0)) {
            return (React.createElement("div", { className: "HeroBannerWithImagesNew__stats-container" },
                React.createElement(Container, null,
                    React.createElement("div", { className: "HeroBannerWithImagesNew__stats" }, stats.map((a, i) => (React.createElement("div", { className: "HeroBannerWithImagesNew__stats-item", key: `statElement${a.title}-${i}` },
                        React.createElement("div", { className: "HeroBannerWithImagesNew__value" }, a.title),
                        React.createElement("div", { className: "HeroBannerWithImagesNew__title" }, a.subTitle))))))));
        }
        return React.createElement(React.Fragment, null);
    }, [emptyStatisticsTest, stats]);
    return (React.createElement("div", { className: mainClassName },
        React.createElement("div", { className: "HeroBannerWithImagesNew__container" },
            React.createElement("div", { className: "HeroBannerWithImagesNew__image--desktop" }, desktopImageEl),
            React.createElement(Container, null,
                React.createElement("div", { className: "HeroBannerWithImagesNew__content" },
                    React.createElement("h1", { className: "titleWrapper__title", dangerouslySetInnerHTML: {
                            __html: title,
                        } }),
                    React.createElement("h5", { className: "titleWrapper__subtitle", dangerouslySetInnerHTML: {
                            __html: subtitle,
                        } }),
                    React.createElement("div", { className: "buttonsWrapper" },
                        React.createElement(ButtonSquare, { label: buttonLabel, href: buttonUrl, openinnewtab: buttonOpenInNewTab.toString() }),
                        React.createElement(React.Fragment, null, (linkLabel && linkHref) ? React.createElement("a", { href: linkHref }, linkLabel) : "")))),
            React.createElement("div", { className: "HeroBannerWithImagesNew__image" }, mobileImageEl),
            cardsRendering())));
};
