import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, ContainerFW } from "../global";
import { TrustpilotCard } from "./TrustpilotCard";
import { viewSizes } from "../../pageViewStyle";
import { ImgV2 } from "../ImgV2";
import { TrustPilotEvaluation } from "./TrustPilotEvalutation/TrustPilotEvaluation";
export const TrustpilotSlider = (props) => (React.createElement("div", { className: "trustpilot-slider-wrapper" },
    React.createElement(ContainerFW, { backgroundColor: "#fff" },
        React.createElement("div", { className: "trustpilot-slider" },
            React.createElement(Container, { className: "g-container--tablet-margins" },
                React.createElement("p", { className: "sliderTitle h4-semi-bold color-dark-default" }, props.title),
                React.createElement(Swiper, { slidesPerView: "auto", breakpoints: {
                        375: {
                            slidesPerView: 1.3,
                            spaceBetween: 30
                        },
                        [viewSizes.smallMaxWidth]: {
                            slidesPerView: 3
                        }
                    } }, props.cards.map((card, i) => React.createElement(SwiperSlide, { key: `trustpilot-slide-${i}` }, React.createElement(TrustpilotCard, { ...card }))))))),
    props.excellentLabel && React.createElement(TrustPilotEvaluation, { excellentLabel: props.excellentLabel, rating: props.rating, starsImage: props.starsImage, trustpilotImage: props.trustpilotImage }),
    props.bottomText && React.createElement("div", { className: "reviews-global-traders-box" },
        React.createElement(ImgV2, { src: props.bottomTextIcon, alt: props.bottomText, className: "account-types-v2__titleIcon" }),
        props.bottomText)));
