import React from "react";
export const ResponsiveImg = ({ sources, children, }) => {
    const hasSources = Array.isArray(sources) && sources?.length;
    if (!hasSources) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement("picture", null,
        sources.map(({ srcset, sizes, media, type }, index) => (React.createElement("source", { key: index, srcSet: srcset, sizes: sizes, media: media, type: type }))),
        children));
};
