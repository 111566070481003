import React from "react";
import { Container, ContainerFW } from "../../global";
import { ButtonV2 } from "../../ButtonV2";
import { ImgV2 } from "../../ImgV2/ImgV2";
import { getColors } from "../../../utils";
import { Heading } from "../../Heading";
export const TradeWithThinkTrader = ({ imageUrlDesktop, imageUrlMobile, informationItems, imageAlt, title, subTitle, buttonLabel, buttonHref, designStyles, cardBackgroundColor, componentMode }) => {
    const { bgColor } = getColors(designStyles?.backgroundColor);
    const textColorClassName = componentMode === "dark" ? "dark-mode-text-color" : "light-mode-text-color";
    return (React.createElement(ContainerFW, { backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--tablet-margins-landing-pages" },
            React.createElement("div", { style: { backgroundColor: cardBackgroundColor ? cardBackgroundColor : "#EFFCEE" }, className: `trade-with-think-trader ${textColorClassName}` },
                React.createElement("div", { className: "trade-with-think-trader__main-container" },
                    React.createElement("div", { className: "trade-with-think-trader__text-content-container" },
                        React.createElement("div", null,
                            React.createElement(Heading, { design: designStyles?.titleDesign }, title),
                            React.createElement(Heading, { design: "h5-lp" }, subTitle)),
                        React.createElement("div", { className: "trade-with-think-trader__cards-container" }, informationItems.map((item, i) => (React.createElement("div", { key: i, className: "trade-with-think-trader__card" },
                            React.createElement("img", { src: item.iconUrl, alt: item.iconAlt }),
                            React.createElement("div", { className: `main-error-container__help-pages-link ${textColorClassName}`, dangerouslySetInnerHTML: {
                                    __html: item.cardHtml,
                                } }))))),
                        React.createElement("div", { className: "trade-with-think-trader__button-container" },
                            React.createElement(ButtonV2, { href: buttonHref, design: designStyles?.buttonDesign }, buttonLabel))),
                    React.createElement("div", { className: "trade-with-think-trader__img-container centered-container" },
                        React.createElement(ImgV2, { smallUrl: imageUrlMobile, largeUrl: imageUrlDesktop, alt: imageAlt })))))));
};
