import React from "react";
import { Container, ContainerFW } from "../../global";
import { Swiper, SwiperSlide, } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { getColors } from "../../../utils";
import { ButtonV2 } from "../../ButtonV2";
import { AcademyArticleCard } from "../AcademyArticleCardDeck/AcademyArticleCard/AcademyArticleCard";
const MySwiper = (props) => {
    const { bgColor } = getColors(props.backgroundColor || "neutralaccentbackground");
    const [swiper, setSwiper] = React.useState();
    const setSwiperInstance = React.useCallback((s) => setSwiper(s), []);
    React.useEffect(() => {
        if (swiper && props.swiperOff) {
            swiper.destroy();
        }
    }, [swiper, props.swiperOff]);
    return (React.createElement("div", { className: "information-card-dark-deck" },
        React.createElement(ContainerFW, { backgroundColor: bgColor },
            React.createElement("div", { className: "color-dark-default" },
                React.createElement(Container, null,
                    React.createElement("div", { className: "information-card-dark-deck__container" },
                        React.createElement("div", { className: "centered-container information-card-dark-deck__title-container" },
                            React.createElement("div", { className: "information-card-dark-deck__title-and-description-container" },
                                React.createElement("h2", null, props.title),
                                React.createElement("h5", { className: "h5-semi-bold color-dark-80" }, props.description))),
                        React.createElement(Swiper, { onSwiper: setSwiperInstance, modules: [Navigation, Pagination], spaceBetween: 24, cssMode: true, slidesPerView: "auto", navigation: {
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            } }, props.content.map((item) => React.createElement(SwiperSlide, { key: `information-card-dark-deck-slide-${item.articleTitle}` },
                            React.createElement(AcademyArticleCard, { ...item })))),
                        props.buttonText && React.createElement("div", { className: "centered-container information-card-dark-deck__bottom-container" },
                            React.createElement(ButtonV2, { design: props.buttonDesign || "ghost-medium-wbg", href: props.buttonUrl }, props.buttonText))))))));
};
export const InformationCardDarkDeckAcademy = (props) => (React.createElement(React.Fragment, null,
    React.createElement("div", { className: "hide-small hide-medium swiper-off" },
        React.createElement(MySwiper, { ...props, swiperOff: true })),
    React.createElement("div", { className: "hide-large" },
        React.createElement(MySwiper, { ...props, swiperOff: false }))));
