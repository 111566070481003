import * as React from "react";
export const Gap = (props) => {
    const divMobileStyle = {
        minHeight: props.mobileGap,
        maxHeight: props.mobileGap,
    };
    const divDesktopStyle = {
        minHeight: props.desktopGap,
        maxHeight: props.desktopGap,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mobile-div", style: {
                ...divMobileStyle,
                backgroundColor: `${props.gapColor === "" ? "#f2f4f6" : props.gapColor}`,
            } }),
        React.createElement("div", { className: "desktop-div", style: {
                ...divDesktopStyle,
                backgroundColor: `${props.gapColor === "" ? "#f2f4f6" : props.gapColor}`,
            } })));
};
