import React, { useCallback } from "react";
import { Img } from "../../../Img";
import { clock } from "../../../svgIcons/clock";
export const getTranslation = (word, cultureCode) => {
    const translations = {
        en: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        eu: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        uk: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        za: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        ky: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        au: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        nz: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        ae: {
            All: "All",
            Beginners: "Beginners",
            Experienced: "Experienced"
        },
        pt: {
            All: "Todos",
            Beginners: "Iniciantes",
            Experienced: "Avançado"
        },
        latam: {
            All: "Todos",
            Beginners: "Principiantes",
            Experienced: "Avanzado"
        },
        tw: {
            All: "所有",
            Beginners: "初學者",
            Experienced: "進階"
        },
        th: {
            All: "ทั้งหมด",
            Beginners: "ผู้เริ่มต้น",
            Experienced: "ขั้นสูง"
        },
        ar: {
            All: "الكل",
            Beginners: "مبتدئين",
            Experienced: "متقدم"
        },
        es: {
            All: "Todos",
            Beginners: "Principiantes",
            Experienced: "Avanzado"
        },
        de: {
            All: "Alle",
            Beginners: "Anfänger",
            Experienced: "Fortgeschritten"
        }
    };
    if (!word) {
        return "";
    }
    return translations[cultureCode || "en"]?.[word] || word;
};
export const AcademyArticleCard = (props) => {
    const renderDetails = useCallback(() => React.createElement("div", { className: "academyArticleCard__details caption2-regular color-dark-60" },
        props.articleReadingTime && React.createElement("span", { className: "centered-container" },
            clock(),
            " ",
            React.createElement("span", { style: { marginTop: "1px" }, className: "" },
                props.articleReadingTime,
                " ",
                props.articleReadingTimeTimeUnit)),
        React.createElement("span", { className: `centered-container academyArticleCard__details-level ${props.articleLevel === "Beginners" ? "academyArticleCard__details-level-beginner" : "academyArticleCard__details-level-advanced"}` },
            React.createElement("span", null, getTranslation(props.articleLevel, props.cultureCode)))), [props.articleLevel, props.articleReadingTime, props.articleReadingTimeTimeUnit]);
    return (React.createElement("div", { className: `academyArticleCard bg-color-neutral-accent-background ${props.isListView ? "academyArticleCard--list" : ""} ${props.articleTags && props.articleTags.length > 0
            ? "academyArticleCard__height-with-tags"
            : ""}` },
        React.createElement("a", { href: props.articleURL, className: "academyArticleCard__img2" },
            React.createElement(Img, { className: "academyArticleCard__img", src: props.imageURL, alt: props.articleImageAlt })),
        props.articleCategory !== undefined && React.createElement("div", { className: "academyArticleCard__tags flex" }, props.articleCategory?.split("|").map((category, i) => React.createElement("a", { className: "academyArticleCard__tag color-dark-80 bg-color-neutral-accent-default", key: `articleBanner__category-${i}`, href: `/${props.culture}${props.categoryLinks?.find(el => el.title.toLowerCase().includes(category.toLowerCase()))?.urlSuffix}` }, props.articleCategoryTranslated?.split("|")[i]))),
        React.createElement("a", { href: props.articleURL, className: "academyArticleCard__title-tag" },
            React.createElement("h4", null, props.articleTitle)),
        React.createElement("p", null, props.articleSummary),
        renderDetails()));
};
