import React, { useCallback } from "react";
const styleOverrides = {
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    color: "inherit",
    backgroundColor: "#fff"
};
export const Dropdown = ({ options = [], selectedOption, placeholder, isPlaceholderDisabled = true, onSelect, classes, style, ariaLabel = "Dropdown", }) => {
    const handleChange = useCallback((event) => {
        if (onSelect) {
            onSelect(event.target.value);
        }
    }, [onSelect]);
    return (React.createElement("div", { className: `dropdown-wrapper ${classes || ""}` },
        React.createElement("select", { onChange: handleChange, className: "p-regular dropdown-wrapper__dropdown", style: { ...styleOverrides, ...style }, "aria-label": ariaLabel, value: selectedOption },
            placeholder && React.createElement("option", { value: "", disabled: isPlaceholderDisabled }, placeholder),
            options.map((option, index) => {
                const [optionText, optionValue] = typeof option === "string" ? [option, option] : [option.text, option.value];
                return (React.createElement("option", { key: index, value: optionValue }, optionText));
            }))));
};
