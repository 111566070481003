export function getColors(backgroundColor) {
    let bgColor;
    let textColor;
    const white = "#FFFFFF";
    const dark = "#0E1D31";
    const nab = "#F2F6F6";
    const nad = "#D3E2E2";
    if (backgroundColor === "white") {
        bgColor = white;
        textColor = dark;
        return { bgColor, textColor };
    }
    if (backgroundColor === "dark") {
        bgColor = dark;
        textColor = white;
        return { bgColor, textColor };
    }
    if (backgroundColor === "neutralaccentbackground") {
        bgColor = nab;
        textColor = dark;
        return { bgColor, textColor };
    }
    if (backgroundColor === "neutralaccentdefault") {
        bgColor = nad;
        textColor = dark;
        return { bgColor, textColor };
    }
    if (backgroundColor === "none") {
        bgColor = undefined;
        textColor = {};
        return { bgColor, textColor };
    }
    if (typeof backgroundColor === "string" && backgroundColor.length > 0) {
        bgColor = backgroundColor;
        textColor = white;
        return { bgColor, textColor };
    }
    bgColor = undefined;
    textColor = {};
    return { bgColor, textColor };
}
