import { debounce } from "debounce";
export const viewSizes = {
    smallMaxWidth: 600,
    mediumMaxWidth: 1024,
};
class PageViewStyle {
    constructor(window) {
        this.getView = () => this.view;
        this.subscribe = (callback) => {
            if (this.subscribers.length > this.maxSubscribers) {
                throw new Error("Maximum subscribers limit reached! Check if something is not unsubscribing.");
            }
            const subscruber = this.subscribers.find(s => callback === s);
            if (subscruber) {
                return false;
            }
            else {
                this.subscribers.push(callback);
                return callback;
            }
        };
        this.unsubscribe = (callback) => {
            const subscriberIndex = this.subscribers.indexOf(callback);
            if (subscriberIndex > -1) {
                this.subscribers.splice(subscriberIndex, 1);
                return true;
            }
            else {
                return false;
            }
        };
        this.onResize = () => {
            this.setView(this.calculateView());
        };
        this.viewClasses = {
            small: "small-view",
            medium: "medium-view",
            large: "large-view",
        };
        this.window = window;
        this.maxSubscribers = 100;
        this.subscribers = [];
        if (this.window.document) {
            this.setView(this.calculateView());
            this.onResizeDebounced = debounce(this.onResize, 50);
            this.window.addEventListener("resize", this.onResizeDebounced);
        }
    }
    setView(view) {
        if (this.view === view) {
            return;
        }
        this.window.document.documentElement.classList.remove(this.viewClasses[this.view]);
        this.window.document.documentElement.classList.add(this.viewClasses[view]);
        this.subscribers.forEach(s => s(view));
        this.view = view;
    }
    calculateView() {
        const width = this.window.innerWidth;
        if (width > viewSizes.mediumMaxWidth) {
            return "large";
        }
        else if (width <= viewSizes.mediumMaxWidth && width > viewSizes.smallMaxWidth) {
            return "medium";
        }
        else {
            return "small";
        }
    }
}
export const pageViewStyle = globalThis.pageviewstyle?.pageViewStyle || new PageViewStyle(globalThis);
