import React from "react";
import DarkStarSet from "../../svgIcons/darkStarSet";
export const TrustpilotCard = (props) => {
    const theMonth = new Date(props.dateValue || "01").toLocaleString(props.monthTranslations, { month: "short" });
    const theDay = new Date(props.dateValue || "01").getDate();
    const theYear = new Date(props.dateValue || "2023").getFullYear();
    const fulldate = `${theDay} ${theMonth} ${theYear}`;
    return (React.createElement("div", { className: "trustpilot-card" },
        React.createElement("h5", { className: "trustpilot-card__title" }, props.title),
        React.createElement("div", { className: "trustpilot-card__rating" },
            DarkStarSet(),
            " ",
            fulldate && React.createElement("sub", { className: "trustpilot-card__date" }, fulldate)),
        React.createElement("div", { className: "trustpilot-card__review", dangerouslySetInnerHTML: {
                __html: props.content,
            } }),
        React.createElement("div", null,
            React.createElement("sub", { className: "trustpilot-card__author" },
                props.author,
                " \u2022 ",
                props.country))));
};
