import React, { useEffect, useRef } from "react";
export const ErrorHandler = ({ stamp }) => {
    const commentRef = useRef();
    useEffect(() => {
        if (commentRef.current) {
            commentRef.current.innerHTML = `<!-- Notey: ${stamp} -->`;
        }
    }, [stamp]);
    return React.createElement("div", { ref: commentRef });
};
